import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text : 'Dashboard',
            link : '/app/'
        },
        {
            type : 'separator'
        },
        {
            text              : user.name,
            link              : '#',
            dropdownPlacement : 'right',
            items             : [
                {
                    text : 'Change Password',
                    link : '/app/change-password/'
                },
                {
                    text : 'Logout',
                    link : '/app/logout/'
                }
            ]
        }
    ];

    const CatalogueAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Data Structure',
            link : '/data-structure/'
        },
        {
            text : 'Catalogue',
            link : '/catalogue/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    const HRAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Staff Listing',
            link : '/staff-listing/'
        },
        {
            text : 'Attendance',
            link : '/attendance/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    const WarehouseAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Request',
            link : '/warehouse/order/request-list/'
        },
        {
            text : 'Purchase Entry',
            link : '/warehouse/entry/list/'
        },
        {
            text : 'Purchase Return',
            link : '/warehouse/return/list/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    const PurchaseAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Request',
            link : '/purchase/order/request-list/'
        },
        {
            text : 'Purchase Order',
            link : '/purchase/order/list/'
        },
        {
            text : 'Purchase Entry',
            link : '/purchase/entry/list/'
        },
        {
            text : 'Purchase Return',
            link : '/purchase/return/list/'
        },
        {
            text : 'Notifications',
            link : '/notifications/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    const FinanceAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Purchase Order',
            link : '/finance/order/list/'
        },
        {
            text : 'Purchase Entry',
            link : '/finance/entry/list/'
        },
        {
            text : 'Purchase Return',
            link : '/finance/return/list/'
        },
        {
            text : 'Notifications',
            link : '/notifications/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    const SalesAdminMenu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text  : 'Sales',
            link  : '#',
            items : [
                {
                    text : 'Orders',
                    link : '/sales/orders/list/'
                },
                {
                    text : 'Invoices',
                    link : '/sales/invoices/list/'
                },
                {
                    text : 'Refunds',
                    link : '/sales/refunds/list/'
                },
                {
                    text : 'Transactions',
                    link : '/sales/transactions/list/'
                }
            ]
        },
        {
            text  : 'Customer',
            link  : '#',
            items : [
                {
                    text : 'Group',
                    link : '/sales/master-data/group/'
                },
                {
                    text : 'Customers',
                    link : '/sales/customer/list/'
                },
                {
                    text : 'Review',
                    link : '/sales/review/list/'
                }
            ]
        },
        {
            text  : 'Marketing',
            link  : '#',
            items : [
                {
                    text : 'Promotions',
                    link : '/sales/marketing/promotions/'
                },
                {
                    text : 'Email Marketing',
                    link : '/sales/email-marketing/'
                }
            ]
        },
        {
            text  : 'Reports',
            link  : '#',
            items : [
                {
                    text : 'Order Reports',
                    link : '/sales/order/report/'
                },
                {
                    text : 'Invoice Reports',
                    link : '/sales/invoice/report/'
                },
                {
                    text : 'Refund Reports',
                    link : '/sales/refund/report/'
                },
                {
                    text : 'Transaction Reports',
                    link : '/sales/transaction/report/'
                }
            ]
        },
        {
            text : 'Notifications',
            link : '/notifications/'
        },
        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user.role === 'catalogue admin') {
        return [
            ...CatalogueAdminMenu
        ];
    } else if (user.role === 'hr admin') {
        return [
            ...HRAdminMenu
        ];
    } else if (user.role === 'warehouse admin') {
        return [
            ...WarehouseAdminMenu
        ];
    } else if (user.role === 'purchase admin') {
        return [
            ...PurchaseAdminMenu
        ];
    } else if (user.role === 'finance admin') {
        return [
            ...FinanceAdminMenu
        ];
    } else if (user.role === 'sales admin') {
        return [
            ...SalesAdminMenu
        ];
    } else if (user.admin) {
        return [
            ...menu
        ];
    } return [
        ...menu
    ];
};
export default getMenu;
